import { t } from "locales"
import * as React from "react"

import DoneIcon from "@mui/icons-material/Done"
import CopyIcon from "@mui/icons-material/FileCopy"
import Button, { ButtonProps } from "@mui/material/Button"

export interface CopyProps extends ButtonProps {
    value: string
}

const Copy: React.FC<CopyProps> = ({ value, ...props }) => {
    const [copied, setCopied] = React.useState(false)

    const handleCopy = async () => {
        if ("clipboard" in navigator) {
            await navigator.clipboard.writeText(value)
        }
        setCopied(true)
    }

    React.useEffect(() => {
        const handle = setTimeout(() => {
            if (copied) {
                setCopied(false)
            }
        }, 1000)
        return () => clearTimeout(handle)
    }, [copied])

    return (
        <Button
            color="primary"
            disabled={copied}
            onClick={handleCopy}
            size="small"
            startIcon={copied ? <DoneIcon /> : <CopyIcon />}
            variant="contained"
            {...props}>
            {copied ? t["copy"].true : t["copy"].false}
        </Button>
    )
}

export default Copy

import Copy from "components/Copy"
import Link from "components/Link"
import { t } from "locales"
import * as React from "react"

import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { styled, useTheme } from "@mui/material/styles"

const qrCodeSize = 246

export default function QRCode({ url, show }: { url: string; show: boolean }) {
    const theme = useTheme()
    const [imgDataUrl, setImgDataUrl] = React.useState("")

    React.useEffect(() => {
        const setDataUrl = async () => {
            const qrCodeURL = new URL(url)
            qrCodeURL.searchParams.set("rel", "qrcode")
            const code = (await import("qrcode")).default
            setImgDataUrl(
                await code.toDataURL(qrCodeURL.toString(), {
                    color: {
                        dark: theme.palette.primary.main
                    },
                    margin: 0,
                    width: 164
                })
            )
        }
        setDataUrl()
    }, [theme.palette?.primary?.main, url])

    const copyLink = new URL(url)
    copyLink.searchParams.set("rel", "copylink")

    return (
        <StyledContainer
            container
            sx={{ display: show ? undefined : "none" }}
            justifyContent="center">
            <StyledImg
                alt="QR"
                height={qrCodeSize}
                width={qrCodeSize}
                loading="lazy"
                src={imgDataUrl}
            />
            <Grid
                container
                item
                xs>
                <Grid
                    item
                    component={Typography}
                    variant="subtitle1"
                    xs={12}>
                    {t.qr.header}
                </Grid>
                <Grid
                    item
                    component={Typography}
                    xs={12}>
                    {t.qr.body}
                </Grid>
                <StyledLink href={url}>{url}</StyledLink>
                <Grid
                    container
                    item
                    className="no-print"
                    justifyContent="flex-end">
                    <Copy
                        color="secondary"
                        value={copyLink.toString()}
                    />
                </Grid>
            </Grid>
        </StyledContainer>
    )
}

const StyledContainer = styled(Grid)(({ theme }) => {
    const { breakpoints, palette, spacing } = theme
    return {
        marginTop: spacing(),
        [breakpoints.up("sm")]: {
            border: `1px solid ${palette.secondary.main}`,
            borderRadius: 2
        },

        "& > *": {
            padding: spacing()
        },

        "@media print": {
            display: "flex"
        }
    }
})

const StyledLink = styled(Link)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: "1rem",
    margin: theme.spacing(1, 0),
    maxWidth: "60ch",
    lineBreak: "anywhere",

    "@media print": {
        color: "inherit"
    }
}))

const StyledImg = styled("img")(() => ({
    maxHeight: qrCodeSize,
    "@media print": {
        filter: "grayscale(100%)",
        height: qrCodeSize,
        width: qrCodeSize
    }
}))
